import React from "react"
import Helmet from "react-helmet"
import {graphql} from "gatsby"
import Layout from "../components/layout"

export default function BlogPost({data}) {
  const post = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <div className="mt-5">
        <h1 className="text-4xl font-bold">{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{__html: post.html}} />
        <small className="text-gray-400">Posted on {post.frontmatter.date}</small>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
      }
      excerpt
    }
  }
`
